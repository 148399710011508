import React from 'react';

import {
  useState,
  useCallback,
} from 'react';

import {
  ShortViewInvoicePopup,
} from "@components/index";

function WithShortViewInvoicePopup(Component) {
  function NewComponent (props) {

    const [currentInvoicePath, setCurrentInvoicePath] = useState(null);

    const isOpen = !!currentInvoicePath;

    const showHandler = useCallback(
      (invoiceFilePath) => handleOnShow(invoiceFilePath, setCurrentInvoicePath),
      [],
    );

    const hideHandler = useCallback(
      () => handleOnHide(setCurrentInvoicePath),
      [setCurrentInvoicePath],
    );

    return (
      <>
        <Component
          {...props}
          onShowShortViewInvoicePopup={showHandler}
        />
        {
          isOpen
            ? (
              <ShortViewInvoicePopup
                key={currentInvoicePath}
                isVisible={true}
                pdfPath={currentInvoicePath}
                onBackgroundClick={hideHandler}
                onCloseButtonClick={hideHandler}
              />
            )
            : null
        }
      </>
    );
  }

  return NewComponent;
}

function handleOnShow(invoiceFilePath, setCurrentInvoicePath) {
  if (!invoiceFilePath) return;
  setCurrentInvoicePath(invoiceFilePath);
}

function handleOnHide(setCurrentInvoicePath) {
  setCurrentInvoicePath(null);
}

export default WithShortViewInvoicePopup;