import types from '@types/index';

// todo: inherit other creators from
function createCell(type, column, value, props) {
  return {type, column, value, props};
}

export function createTextCell(column, value) {
  return {
    type: types.CellType.type.TEXT,
    name: '',
    column: column,
    value: value,
    props: {}
  };
}

export function createBackLightedTextCell(column, value) {
  return createCell(
    types.CellType.type.HIGHLIGHTED_TEXT,
    column,
    value,
  );
}

export function createButtonCell(column, value) {
  return createCell(
    types.CellType.type.BUTTON,
    column,
    value,
  );
}

export function createBookingCell(column, status) {
  return {
    type: types.CellType.type.BOOKING,
    name: '',
    column: column,
    value: '',
    props: {
      status: status,
    },
  };
}

export function createInvoicesCell(column, links, addButtonPayload, isAddButtonVisible) {
  return {
    type: types.CellType.type.INVOICE_LINKS,
    name: '',
    column: column,
    props: {
      links: links,
      addButtonPayload: addButtonPayload,
      isAddButtonVisible: isAddButtonVisible,
    },
  };
}

export function createColumn(name, label, className, fieldProps) {
  return {
    name,
    className,
    titleCell: {
      type: types.CellType.type.TITLE,
      name,
      value: label,
      className,
      props: {},
    },
    fieldCell: {
      type: fieldProps?.type,
      name,
      className,
      column: name,
      validator: fieldProps?.validator,
      props: fieldProps,
    },
  };
}

export function createConfirmPaymentsCell(column, users, editButtonPayload, deleteButtonPayload) {
  return {
    type: types.CellType.type.CONFIRM_PAYMENTS,
    name: '',
    column: column,
    value: '',
    props: {
      users,
      editButtonPayload,
      deleteButtonPayload,
    },
  };
}

export function createNetGrossAmount(column, value, isPositive) {
  return {
    type: types.CellType.type.NET_GROSS_AMOUNT,
    name: '',
    column: column,
    value: value,
    props: {
      isPositive: isPositive
    }
  };
}

export function createConfirmUserCell(column, paymentId, users) {
  return {
    type: types.CellType.type.CONFIRM_PAYMENTS,
    column: column,
    props: {
      paymentId: paymentId,
      currentUserId: 8,
      users: users.map(user => ({
        id: user.id,
        name: user.name,
        hasConfirmed: user.hasConfirmed,
      })),
    },
  };
}

export function createCheckboxCell(column) {
  return {
    type: types.CellType.type.CHECKBOX,
    column: column,
  };
}

export function createPaymentAmountCell(column, paymentStatus, value) {
  return {
    type: types.CellType.type.PAYMENT_AMOUNT,
    name: '',
    column: column,
    value: value,
    props: {
      paymentStatus: paymentStatus,
    },
  };
}

export function createRadioButtonCell(column, value) {
  return createCell(
    types.CellType.type.RADIO_BUTTON,
    column,
    !!value,
    {},
  );
}

export function createLinkCell(column, label, link, url) {
  return {
    type: types.CellType.type.LINK,
    column: column,
    props: {
      label,
      link,
      url,
    }
  };
}