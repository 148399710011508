import '../Table.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {
  useCallback,
} from 'react';

import {
  Button,
} from '@components/index';

ButtonCell.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function ButtonCell({className, value, label, onClick, ...props}) {
  const onClickHandler = useCallback(
    () => onClick(value),
    [value, onClick],
  );

  return (
    <div
      {...props}
      className={[
        'table__button-cell',
        className,
      ].join(' ')}
    >
      <Button
        onClick={onClickHandler}
      >
        {label}
      </Button>
    </div>
  );
}

export default ButtonCell;