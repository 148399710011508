import './PDFViewer.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {
    Loader,
} from '@components/index';

import { jsPDF } from 'jspdf'; // Wichtig: Korrekte Importweise für neuere Versionen von jsPDF

PDFViewer.propTypes = {
    className: PropTypes.string,
    canvases: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool,
    onPdfMouseLeave: PropTypes.func.isRequired,
    onMagnifierMouseMove: PropTypes.func.isRequired,
    onMagnifierContainerClick: PropTypes.func.isRequired,
    onScrollContainerScroll: PropTypes.func.isRequired,
    scrollContainerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    magnifierContainerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    magnifierCanvasRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    pfdRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

PDFViewer.defaultProps = {
    canvases: [],
};

function PDFViewer({className, canvases, isLoading,
                       onPdfMouseLeave, onMagnifierMouseMove, onMagnifierContainerClick, onScrollContainerScroll,
                       scrollContainerRef, magnifierContainerRef, magnifierCanvasRef, pfdRef, ...props}) {

    const downloadPdf = () => {
        if (canvases.length === 0) return;

        const pdf = new jsPDF(); // jsPDF korrekt als Klasse instanziieren

        canvases.forEach((canvasRef, index) => {
            const canvas = canvasRef.current;
            if (canvas) {
                const imgData = canvas.toDataURL('image/png');
                if (index > 0) pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // A4-Größe in mm (210x297)
            }
        });

        pdf.save('download.pdf'); // Speichert die Datei mit dem Namen "download.pdf"
    };

    return (
        <div
            {...props}
            className={[
                'pdf-viewer',
                className,
            ].join(' ')}
        >
            <button className="button  button_type_primary" onClick={downloadPdf}>
                Download PDF
            </button>
            <div className={'pdf-viewer__scroll-container'} onScroll={onScrollContainerScroll} ref={scrollContainerRef}>
                {
                    isLoading
                        ? <Loader className={'pdf-viewer__loader'}/>
                        : null
                }
                <div
                    className={[
                        'pdf-viewer__pdf',
                        isLoading ? 'pdf-viewer__pdf_hidden' : null,
                    ].join(' ')}
                    onClick={onMagnifierContainerClick}
                    onMouseMove={onMagnifierMouseMove}
                    onMouseLeave={onPdfMouseLeave}
                    ref={pfdRef}
                >
                    <div className={'pdf-viewer__magnifier-container'} ref={magnifierContainerRef}>
                        <canvas className={'pdf-viewer__magnifier'} ref={magnifierCanvasRef}/>
                    </div>
                    {
                        canvases.map((ref, key) => <canvas className={'pdf-viewer__canvas'} key={key} ref={ref}/>)
                    }
                </div>
            </div>
        </div>
    );
}

export default PDFViewer;
